interface UsePaginationOptions {
  /** Initial number of items per page. Defaults to 20 */
  hitsPerPage?: number
  /** URL parameter key for hits per page. Defaults to 'hitsperpage' */
  hitsPerPageKey?: string
  /** URL parameter key for current page. Defaults to 'page' */
  pageKey?: string
}

/**
 * Composable for handling pagination with URL state synchronization
 * 
 * @param options Configuration options for pagination
 * @returns Object containing page and hitsPerPage reactive references
 * 
 * @example
 * ```typescript
 * const { page, hitsPerPage } = usePagination({
 *   hitsPerPage: 25,
 *   pageKey: 'p',
 *   hitsPerPageKey: 'size'
 * })
 * ```
 */
export const usePagination = (options?: UsePaginationOptions) => {
  const { currentRoute } = useRouter()
  const pageKey = options?.pageKey ?? 'page'
  const hitsKey = options?.hitsPerPageKey ?? 'hitsperpage'

  // Transform for validating page numbers
  const pageTransform = {
    toUrl: (val: number) => val.toString(),
    fromUrl: (val: string) => {
      const num = Number(val)
      return !isNaN(num) ? Math.max(1, num) : 1
    }
  }

  // Transform for validating hits per page
  const hitsTransform = {
    toUrl: (val: number) => val.toString(),
    fromUrl: (val: string) => {
      const num = Number(val)
      if (isNaN(num)) return options?.hitsPerPage || 20
      return Math.min(Math.max(1, num), 500)
    }
  }

  // Initial getters that first check URL state
  const getInitialPage = () => {
    const urlPage = currentRoute.value.query[pageKey]
    if (urlPage && !isNaN(Number(urlPage))) {
      return pageTransform.fromUrl(urlPage.toString())
    }
    return 1
  }

  const getInitialHitsPerPage = () => {
    const urlHits = currentRoute.value.query[hitsKey]
    if (urlHits && !isNaN(Number(urlHits))) {
      return hitsTransform.fromUrl(urlHits.toString())
    }
    return options?.hitsPerPage ?? 20
  }

  const { value: page } = useParam(
    getInitialPage,
    {
      key: pageKey,
      transform: pageTransform,
    }
  )

  const { value: hitsPerPage } = useParam(
    getInitialHitsPerPage,
    {
      key: hitsKey,
      transform: hitsTransform
    }
  )


  const resetPagination = () => {
    page.value = 1
    hitsPerPage.value = options?.hitsPerPage ?? 20
  }

  return {
    page,
    hitsPerPage,
    resetPagination
  }
}