<template>
  <NuxtLink class="flex gap-4 items-center h-full" :to="props.to" @click.stop>
    <UAvatar :src="image" :alt="name" :icon="icon || icons.onderwijsinstelling" size="sm" class=" bg-white dark:bg-white" />
    <p 
      class="text-[11px] sm:text-sm font-bold dark:text-white"
      style="margin-bottom: 0px;"
      :class="[
        !expanded ? 'max-w-32 sm:max-w-48 truncate' : 'whitespace-nowrap',
        headingClass
      ]"
    >{{ name }}</p>
  </NuxtLink>
</template>

<script lang="ts" setup>
// NOTE Since RowHead is used within a selectable row (aka @select), it should stop navigation handler from select fn()

const props = withDefaults(defineProps<{
  image?: string
  to: string
  name: string
  expanded?: boolean
  headingClass?: string
  icon?: string
}>(), {})

const { icons } = useConstants()

</script>

<style>

</style>