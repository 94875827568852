import { liteClient, type LiteClient, type SearchResponses, type SearchResponse, type SearchMethodParams, type SearchQuery, type SearchStrategy } from "algoliasearch/lite";
import { createInMemoryCache } from '@algolia/cache-in-memory';
import { createFetchRequester } from "@algolia/requester-fetch";


export declare type RequestOptions = {
  /**
   * If the given request should persist on the cache. Keep in mind,
   * that some methods may have this option enabled by default.
   */
  readonly cacheable?: boolean;
  /**
   * Custom timeout for the request. Note that, in normal situacions
   * the given timeout will be applied. But the transporter layer may
   * increase this timeout if there is need for it.
   */
  readonly timeout?: number;
  /**
   * Custom headers for the request. This headers are
   * going to be merged the transporter headers.
   */
  readonly headers?: Readonly<Record<string, string>>;
  /**
   * Custom query parameters for the request. This query parameters are
   * going to be merged the transporter query parameters.
   */
  readonly queryParameters?: Record<string, any>;
  /**
   * Custom data for the request. This data are
   * going to be merged the transporter data.
   */
  readonly data?: Record<string, any>;
  /**
   * Additional request body values. It's only taken in
   * consideration in `POST` and `PUT` requests.
   */
  [key: string]: any;
};


type SearchMultipleResponses <T = Record<string, unknown>> = {
  results: SearchResponse<T>[];
}



export const useAlgoliaSearch = () => {

  const {
    apiKey,
    applicationId,
    cache,
    cookieName,
    cookieMaxAge
  } = useRuntimeConfig().public.algolia

  function createAlgoliaToken(): string {
    const { randomString } = useRandom()
    return randomString(16, { withNumbers: true })
  }

  const { isDev } = useRuntimeConfig().public.mode

  function getAlgoliaToken(): string {
    const cookie = useCookie(cookieName, { maxAge: cookieMaxAge, secure: !isDev })
    if (!cookie.value) cookie.value = createAlgoliaToken()
    return cookie.value
  }

  const client = liteClient(applicationId, apiKey, {
    baseHeaders: {
      'X-Algolia-UserToken': getAlgoliaToken() || 'anonymous',
    },
    requester: createFetchRequester(),
    responsesCache: cache ? createInMemoryCache() : undefined,
    requestsCache: cache ? createInMemoryCache({ serializable: false }) : undefined
  });
  


  const searchMultiple = <T>(searchMethodParams: SearchMethodParams, requestOptions?: RequestOptions): Promise<SearchMultipleResponses<T>> => {
    return client.search(searchMethodParams, requestOptions) as Promise<SearchMultipleResponses<T>>
  }

  const searchSingle = <T>(searchMethodParams: SearchQuery & { strategy?: SearchStrategy}, requestOptions?: RequestOptions): Promise<SearchResponse<T>> => {
    const { strategy, ...rest } = searchMethodParams
    return client.search({
        requests: [
          {
            ...rest
          }
        ],
        strategy
    }, requestOptions)
      .then((res) => {
        const singleIndexData = res.results[0]
        return singleIndexData as SearchResponse<T>
      })
  }



  return {
    client,
    searchMultiple, 
    searchSingle
  }
}
