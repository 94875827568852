

<script lang="ts" setup>
import type { AlgoliaOnderwijsInstelling, AlgoliaRegioloket, AlgoliaOpleiding } from '~~/types/algolia';

type RegioloketProps = {
  type: 'regioloketten',
  data?: AlgoliaRegioloket[]
}

type OpleidingenProps = {
  type: 'opleidingen',
  data?: AlgoliaOpleiding[]
}

type OnderwijsInstellingProps = {
  type: 'onderwijsinstellingen',
  data?: AlgoliaOnderwijsInstelling[]
}

type ContentTypeProps = RegioloketProps | OpleidingenProps | OnderwijsInstellingProps

type Column = {
  key: string
  label: string
}

const props = withDefaults(
  defineProps<{
    slug?: string
    loading: boolean,
    error: any
    columns: Column[]
    useHeader?: boolean
    removeFirstLastPadding?: boolean

    usePagination?: boolean
    totalHits?: number
  } & ContentTypeProps>(),
  {
    useHeader: true,
    usePagination: false,
  }
)

const page = defineModel('page')
const hitsPerPage = defineModel('hitsPerPage')

watch(hitsPerPage, () => {
  page.value = 1
})

const emit = defineEmits<{
  (e: 'refresh'): void
}>()

const retryCount = ref(0)
const maxRetries = 3
const retry = () => {
  retryCount.value++
  emit('refresh')
}

const columns = computed(() => {
  const list = props.columns.map(rec => {
    return {
      ...rec,
      headerKey: rec.key + '-header',
      dataKey: rec.key + '-data'
    }
  })

  return list
})


const onSelect = (row: AlgoliaRegioloket | AlgoliaOpleiding | AlgoliaOnderwijsInstelling) => {
  navigateTo(`/${props.slug || props.type}/` + row.slug)
}

const { icons } = useConstants()

const emptyLabel = computed(() => `Geen ${props.type} gevonden`)
const errorLabel = computed(() => retryCount.value < maxRetries  ? `Er ging iets mis met het zoeken naar ${props.type}` : 'Het lukt noog steeds niet... Ververs de pagina of probeer het later nog een keer.')

const hitsPerPageOptions = [
  10,
  20,
  50,
  100,
  250,
  500
]

const { isProd } = useRuntimeConfig().public.mode

</script>

<template>
  <UTable 
    :columns="columns"
    :rows="data" 
    :ui="{
      thead: useHeader ? '' : 'hidden',
      divide: useHeader ? undefined : 'divide-none',
      th: {
        base: 'bg-grays-50 dark:bg-gray-800/50 first:rounded-tl-lg last:rounded-tr-lg select-none',
        padding: 'first:pl-6 last:pr-6 md:px-6 lg:px-8',
        color: 'text-gray-500 dark:text-gray-400',
        size: 'text-xs'
      },
      td: {
        padding: removeFirstLastPadding ? 'first:pl-0 last:pr-0 py-2 sm:py-3 md:py-4 md:px-6 lg:px-8' : 'first:pl-6 last:pr-6 py-2 sm:py-3 md:py-4 md:px-6 lg:px-8',
        color: 'text-gray-700 dark:text-gray-200'
      },
      tr: {
        active: 'dark:hover:bg-gray-700/50'
      },
      wrapper: !useHeader ? 'overflow-x-auto' : 'overflow-x-auto ring-1 ring-gray-100 dark:ring-gray-800/50 rounded-lg'
    }"
    :loading="loading"
    @select="onSelect"
  >
    <template
      v-for="col in columns"
      v-slot:[col.headerKey]="slotProps"
    >
      <span>{{ col.label }}</span>
    </template>

    <template
      v-for="col in columns"
      v-slot:[col.dataKey]="slotProps"
    >
      <slot 
        :name="col.dataKey" 
        :column="slotProps.column" 
        :row="slotProps.row" 
      />
    </template>
    
    <template #loading-state>
      <div class="flex flex-col divide-y divide-gray-200 dark:divide-gray-700">
        <div v-for="i in hitsPerPage" class="px-6 py-3 sm:py-5 md:py-[21px] flex items-center gap-6 md:gap-12 lg:gap-16">
          <div class="flex gap-4 items-center h-full">
            <USkeleton class="h-8 w-8 shrink-0 rounded-full" />
            <USkeleton class="h-4 w-48 sm:w-80 rounded-full" />
          </div>
          
          <USkeleton v-for="i in columns.length - 1" class="h-4 w-48 rounded-full" />
        </div>
      </div>
    </template>

    <template #empty-state>
      <div v-if="error" class="flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14 gap-4 bg-error-50 dark:bg-error-950/20 overflow-x-scroll w-full">
        <UIcon name="i-heroicons-exclamation-triangle-20-solid" class="text-error-500 w-6 h-6" />
        <p class="text-sm text-center text-gray-900 dark:text-white">{{ errorLabel }}</p>
        <UButton 
          v-if="retryCount < maxRetries"
          color="error" 
          size="sm" 
          :loading="loading" 
          variant="soft" 
          :disabled="loading" 
          @click="retry" 
          label="probeer opnieuw" 
        />
        <pre v-if="!isProd" class="">{{ error }}</pre>
      </div>
      <div v-else class="flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14 gap-4">
        <UIcon :name="icons[type]" class="text-gray-500 w-6 h-6" />
        <p class="text-sm text-center text-gray-900 dark:text-white">{{ emptyLabel }}</p>
      </div>
    </template>
  </UTable>
  <div v-if="usePagination" class="flex gap-4 flex-wrap justify-between">
    <USelectMenu 
      v-model="hitsPerPage" 
      :options="hitsPerPageOptions"
      size="lg"
    >
      <template #label>
        <span>{{ hitsPerPage }} {{ hitsPerPage === 1 ? 'resultaat' : 'resultaten' }}</span>
      </template>
    </USelectMenu>

    <UPagination 
      v-model="page" 
      :page-count="hitsPerPage" 
      :total="totalHits || 0" 
      size="lg"
      :ui="{
        wrapper: 'items-stretch'
      }"
    />
  </div>
</template>