<template>
  <div class="relative p-4 w-80 md:w-[30rem] max-w-full text-left cursor-default" @click.stop>
    <div class="flex gap-4 items-center mb-4">
        <UAvatar v-if="image" :src="image" size="lg" class="bg-white dark:bg-white" />
        <h5 class="text-sm sm:text-md font-bold text-gray-900 dark:text-gray-100 mb-0 whitespace-normal">
            {{ heading }}
        </h5>
    </div>
    <UDivider class="my-4"/>

    <div class="flex flex-col gap-3">
        <slot />
    </div>

    <div v-if="path || externalUrl" class="mt-6 flex gap-3 flex-wrap">
      <UButton 
          v-if="path"
          icon="i-heroicons-chevron-right-16-solid"
          trailing
          variant="soft"
          @click="navigate"
      >
          bekijk profiel
      </UButton>
      <UButton 
          v-if="externalUrl"
          :to="externalUrl"
          icon="i-heroicons-arrow-up-right-16-solid"
          trailing
          variant="ghost"
          target="_blank"
          external
          color="white"
      >
          {{ externalLabel || 'naar website' }}
      </UButton>
  </div>

    


    
  </div>
</template>

<script lang="ts" setup>

const props = defineProps<{ 
    image?: string
    heading: string
    path?: string
    externalUrl?: string
    externalLabel?: string
}>()

const navigate = () => {
    const { trackEvent } = useTracking()
    trackEvent('navigate_to_profile_via_preview', {
        event_category: 'ui',
        event_label: 'previewcard',
        event_value: props.heading
    })
    navigateTo(props.path)
}


</script>

<style>

</style>