// Previous type definitions remain the same...
type RouterMethod = 'push' | 'replace'

export interface Transform<T> {
  fromUrl: (val: string) => T
  toUrl: (val: T) => string | string[] | number | undefined
  onError?: (error: Error) => void
}

interface BaseOptions {
  key: string
  useUrlState?: boolean
  debounce?: number
  history?: RouterMethod
}

interface GetterOptions<T> extends BaseOptions {
  transform?: Transform<T>
  defaultValue?: T
}

interface NoGetterOptions<T> extends BaseOptions {
  transform?: Transform<T>
  defaultValue: T
}

const defaultOptions = {
  useUrlState: true,
  history: 'replace' as RouterMethod,
  transform: {
    fromUrl: (val: string | string[]) => val,
    toUrl: (val: unknown) => String(val)
  }
}

export function useParam<T>(
  options: NoGetterOptions<T>
): {
  value: Ref<T>
  setValue: (val: T) => void
  reset: () => void
}
export function useParam<T>(
  getter: () => T,
  options: GetterOptions<T>
): {
  value: Ref<T>
  setValue: (val: T) => void
  reset: () => void
}
export function useParam<T>(
  getterOrOptions: (() => T) | NoGetterOptions<T>,
  maybeOptions?: GetterOptions<T>
) {
  const { currentRoute, push, replace } = useRouter()
  
  // Determine if first argument is getter or options
  const getter = typeof getterOrOptions === 'function' ? getterOrOptions : undefined
  const options = typeof getterOrOptions === 'function' ? maybeOptions! : getterOrOptions
  
  const mergedOptions = {
    ...defaultOptions,
    ...options,
    transform: {
      ...defaultOptions.transform,
      ...options.transform
    }
  }

  const getInitialValue = (): T => {
    // If getter is provided, use it
    if (getter) {
      return getter()
    }
    
    // At this point we know defaultValue exists because of NoGetterOptions type
    const queryValue = currentRoute.value.query[options.key]

    if (typeof queryValue === 'string' || Array.isArray(queryValue)) {
      try {
        return mergedOptions.transform.fromUrl(queryValue as any) as T
      } catch (error) {
        mergedOptions.transform.onError?.(error as Error)
      }
    }
    
    // We know this exists because of NoGetterOptions type
    return (options as NoGetterOptions<T>).defaultValue
  }

  const initialValue = getInitialValue()
  const internalValue = ref(initialValue) as Ref<T>

  // Create debounced router method
  const routerMethod = mergedOptions.history === 'push' ? push : replace
  const debouncedRouterMethod = mergedOptions.debounce 
    ? useDebounceFn(routerMethod, mergedOptions.debounce)
    : routerMethod

  // Computed property
  const value = computed({
    get: () => internalValue.value,
    set: (newValue: T) => {
      // Update internal value
      internalValue.value = newValue

      // Update URL if enabled
      if (mergedOptions.useUrlState) {
        try {
          const urlValue = mergedOptions.transform.toUrl!(newValue)
          const newQuery = {
            ...currentRoute.value.query,
            [mergedOptions.key]: !urlValue ? undefined : urlValue
          }

          debouncedRouterMethod({
            query: newQuery
          })
        } catch (error) {
          mergedOptions.transform.onError?.(error as Error)
          return
        }
      }
    }
  })

  // Reset function
  const reset = () => {
    if (getter) {
      value.value = getter()
    }

    value.value = (options as NoGetterOptions<T>).defaultValue
  }

  // Explicit setValue function
  const setValue = (val: T) => {
    value.value = val
  }

  return {
    value,
    setValue,
    reset
  }
}


import type { UserLocation } from "~~/types";

export const useParamHelpers = () => {
  const { currentRoute, replace } = useRouter()

  const removeQueryKeys = (keys: string | string[]) => {
    let values = typeof keys === 'string' ? [keys] : keys
    const newQuery: Record<string, any> = {
      ...currentRoute.value.query
    }

    values.forEach(val => newQuery[val] = undefined)

    replace({
      query: newQuery
    })
    
  }

  const removeAllQueryKeys = () => {
    replace({
      query: {}
    })
  }


  const copyCurrentUrlQuery = (location?: UserLocation) => {
    if (!window) return ''
    // Get current URL
    const url = new URL(window.location.href);

    // Define the query parameters to remove
    const paramsToRemove = ['lat', 'lng', 'address', 'radius'];
    // Remove existing params from the URL
    paramsToRemove.forEach(param => url.searchParams.delete(param));

    if (location && location.lat && location.lng) {
      // Add new params with provided values
      url.searchParams.append('lat', location.lat.toString());
      url.searchParams.append('lng', location.lng.toString());
      url.searchParams.append('address', location.address);
      url.searchParams.append('radius', location.radius);
    }

    return url.toString()
  }

  return {
    removeQueryKeys,
    removeAllQueryKeys,
    copyCurrentUrlQuery
  }
}