<template>
  <div 
    v-if="useDistance"
    class="inline-flex flex-row gap-1 items-center text-[11px] text-gray-500 rounded-md px-1.5 leading-5  whitespace-nowrap" 
    :class="badge ? 'border border-gray-300 bg-gray-50 dark:bg-gray-800 dark:border-gray-600' : ''"
    >
      <UIcon name="i-mdi-crosshairs-gps"></UIcon>

      <span>
        <slot name="pre" />
        {{ rounded }} km
        <slot name="post" />
      </span>
  </div>
</template>

<script lang="ts" setup>
 const props = withDefaults(defineProps<{distance?: number, badge?: boolean}>(), { })
 const useDistance = computed(() => typeof props.distance === 'number')
 const rounded = computed(() => !props.distance ? 0 : Math.round((props.distance / 1000) * 10) / 10) // Returns 3.5
</script>

<style>

</style>