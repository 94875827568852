export const useRandom = () => {

    const randomNumber = (min: number, max: number) :number => {
        // find diff
        let difference = max - min;

        // generate random number 
        let rand = Math.random();

        // multiply with difference 
        rand = Math.floor(rand * difference);

        // add with min value 
        rand = rand + min;
        return rand
    }

    const randomString = (length: number, options?: { withNumbers?: boolean, withSpecial?: boolean }): string => {
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        if (options?.withNumbers) characters += '0123456789'
        if (options?.withSpecial) characters += '@#$%^&*()-_!'

        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    

    return { randomNumber, randomString };
}